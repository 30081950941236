.MainContainer {
    width: 80%;
    max-width: 1360px;
    padding: 0 10%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

@media (max-width: 850px) {
    .MainContainer {
        width: 88%;
        padding: 0 6%;
    }
}