.ProfileSub {
    font-size: 36px;
    font-weight: 700;
}

.ProfileRow {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.ProfileMenu {
    width: 210px;
}

.PMenuBtn {
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;

    &.Active {
        font-weight: 800;
    }
}

.PMenuBtn+.PMenuBtn {
    margin-top: 22px;
}

@media (max-width: 1430px) {
    .ProfileMenu {
        display: none;
    }
}

@media (max-width: 850px) {
    .ProfileSub {
        margin: 26px 0 0 0;
        font-size: 20px;
    }
}