.CRMItemRow {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.CRMItemImgBox {
    width: 60px;
    height: 50px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        width: 100%;
        border-radius: 6px;
        overflow: hidden;
    }
}

.CRMItemUid {
    width: fit-content;
    font-size: 14px;
    font-weight: 500;
    color: #121212;
    padding: 2px 5px;
    border-radius: 6px;
    background-color: #e6e6e6;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.2s ease;

    &:hover {
        background-color: #d9d9d9;
    }
}

.CRMItemCopyIcon {
    margin-left: 5px;
}

.CRMItemName {
    font-size: 14px;
    font-weight: 500;
    color: #121212;
    max-width: 120px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.CRMItemPrice {
    min-width: 120px;

    span {
        width: fit-content;
        font-size: 14px;
        font-weight: 500;
        color: #121212;
        padding: 2px 5px;
        border-radius: 6px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
}

.CRMItemPrice.Blue {
    span {
        background-color: #d2e7ff;
    }
}

.CRMItemPrice.Orange {
    span {
        background-color: #ffebd2;
    }
}

.CRMItemOrders {
    font-size: 14px;
    font-weight: 500;
    color: #121212;
}

.CRMItemDate {
    font-size: 14px;
    font-weight: 500;
    color: #121212;
}

@media (max-width: 850px) {
    .CRMItemUid {
        font-size: 12px;
    }

    .CRMItemPrice {
        span {
            font-size: 12px;
        }
    }
}