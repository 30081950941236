@use '../../../base.scss';

.CRMStories {
    width: calc(100% - 230px);
    padding-left: 20px;
    border-left: solid 1px #EAEAEA;
    display: flex;
    flex-direction: column;

    select {
        width: 300px;
        border: solid 1px #b3b3b3;
        height: 40px;
        padding: 0 14px;
        border-radius: 6px;
        font-family: 'Manrope';
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
        color: #121212;
    }

    option {
        font-family: 'Manrope', sans-serif;
        font-size: 14px;
        font-weight: 500;
        color: #121212;
    }
}

.StoriesFileInput {
    margin-top: 10px;
    width: 328px;
    border: solid 1px #b3b3b3;
    height: 40px;
    border-radius: 6px;
    overflow: hidden;
    color: #121212;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;

    span {
        font-family: 'Manrope', sans-serif;
        font-size: 14px;
        font-weight: 500;
        margin-left: 17px;
    }

    input {
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
        position: absolute;
        left: 0;
        top: 0;
    }
}

.StoriesSave {
    width: fit-content;
    font-size: 14px;
    font-weight: 600;
    width: 330px;
    height: 42px;
    background-color: #121212;
    color: white;
    border-radius: 8px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: opacity .1s linear, transform .1s linear, background-color .2s ease;
    pointer-events: none;
    background-color: #808080;

    @media (hover: hover) {
        &:hover {
            opacity: 0.9;
        }

        &:active {
            transform: scale(0.995);
        }
    }

    @media (pointer: coarse) {
        &:active {
            opacity: 0.8;
        }
    }
}

.StoriesSave.Active {
    pointer-events: auto;
    background-color: #121212;
}

@media (max-width: 1430px) {
    .CRMStories {
        padding-left: 0;
        border-left: none;
        width: 100%;
    }
}