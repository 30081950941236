@use '../../../base.scss';

.CRMUsers {
    width: calc(100% - 230px);
    padding-left: 20px;
    border-left: solid 1px #EAEAEA;
}

.RedRole {
    color: rgb(0, 195, 0);
}

.UsersAddRole {
    margin-bottom: 5px !important;
}

.UserAddRole {
    width: 100%;
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
    color: #125097;
}

.CheckRole {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    input {
        width: 16px;
        height: 16px;
        cursor: pointer;
    }

    span {
        margin-left: 10px;
        font-size: 14px;
        font-weight: 500;
    }
}

.GenerateKey {
    width: fit-content;
    // max-width: 100px;
    font-size: 12px;
    font-weight: 500;
    color: #121212;
    padding: 2px 5px;
    border-radius: 6px;
    background-color: #e6e6e6;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.2s ease;

    &:hover {
        background-color: #d9d9d9;
    }

    span {
        margin-left: 5px;
        max-width: 100px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

@media (max-width: 1430px) {
    .CRMUsers {
        padding-left: 0;
        border-left: none;
        width: 100%;
    }
}