@use '../../base.scss';

.ProfileSettings {
    width: calc(100% - 230px);
    padding-left: 20px;
    border-left: solid 1px #EAEAEA;
    display: flex;
    flex-direction: column;
}

.ProfileSettingsSub {
    // font-size: 12px;
    // font-weight: 700;
    // margin-bottom: 8px;

    font-size: 16px;
    font-weight: 700;
    color: #020202;
    margin-bottom: 12px;
}

.ProfilePassInput {
    width: 300px;
    outline: none;
    padding: 14px;
    font-size: 16px;
    font-weight: 500;
    font-family: 'Manrope', sans-serif;
    border: solid 1px #EAEAEA;
    border-radius: 6px;

    &::placeholder {
        color: #A6A6A6;
    }
}

.ProfilePassInput+.ProfilePassInput {
    margin-top: 10px;
}

.ProfileSavePass {
    margin-top: 10px;
    width: 328px;
    max-width: 100%;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    background-color: #A6A6A6;
    color: white;
    border-radius: 8px;
    cursor: pointer;
    transition: opacity .1s linear, transform .1s linear;
    pointer-events: none;

    @media (hover: hover) {
        &:hover {
            opacity: 0.9;
        }

        &:active {
            transform: scale(0.995);
        }
    }

    @media (pointer: coarse) {
        &:active {
            opacity: 0.8;
        }
    }
}

.ProfileSavePass.Active {
    pointer-events: auto;
    background-color: #121212;
}

.ProfilePassTip {
    font-size: 12px;
    color: #121212;
    margin-top: 10px;
}

.ProfilePassTip.Red {
    color: #db0000;
}

.ProfilePassTip.Green {
    color: #58c400;
}

@media (max-width: 1430px) {
    .ProfileSettings {
        padding-left: 0;
        border-left: none;
    }
}

@media (max-width: 850px) {
    .ProfileSettings {
        width: 100%;
    }

    .ProfileSettingsSub {
        font-size: 12px;
        font-weight: 700;
        margin-bottom: 8px;
    }

    .ProfilePassInput {
        font-size: 12px;
        font-weight: 600;
    }

    .ProfileSavePass {
        height: 40px;
        font-size: 12px;
        font-weight: 600;
    }
}

@media (max-width: 390px) {
    .ProfilePassInput {
        width: calc(100% - 28px);
    }

    .ProfileSavePass {
        width: 100%;
    }
}