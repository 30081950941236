@use '../../base.scss';

.CRM {
    width: 80%;
    max-width: 1360px;
    min-height: calc(100vh - 80px);
    padding: 0 10%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.CRMContainer {
    h2 {
        font-size: 36px;
        font-weight: 700;
        color: #020202;
    }

    h4 {
        font-size: 18px;
        font-weight: 700;
        margin: 0 0 12px 0;
        color: #121212;
    }

    p {
        font-size: 16px;
        font-weight: 500;
        line-height: 160%;
        color: #121212;
        margin: 0 0 12px 0;
    }
}

.CRMLogIn {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    h4 {
        font-size: 20px;
        color: #808080;
        font-weight: 600;
    }
}

.CRMLogInput {
    width: 300px;
    outline: none;
    padding: 14px;
    font-size: 16px;
    font-weight: 500;
    font-family: 'Manrope', sans-serif;
    border: solid 1px #EAEAEA;
    border-radius: 6px;

    &::placeholder {
        color: #A6A6A6;
    }
}

.CRMLogInput+.CRMLogInput {
    margin-top: 10px;
}

.CRMLogBtn {
    margin-top: 10px;
    width: 328px;
    height: 56px;
    max-width: 100%;
    font-size: 16px;
    font-weight: 600;
    color: white;
    background-color: #808080;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    cursor: pointer;
    @include base.btn();
    pointer-events: none;
}

.CRMLogBtn.Active {
    background-color: #121212;
    pointer-events: auto;
}

.CRMPassTip {
    font-size: 12px;
    margin-top: 10px;
    color: #db0000;
}

.CRMRow {
    width: 100%;
    display: flex;
    flex-direction: row;
}

@media (max-width: 850px) {
    .CRM {
        width: 88%;
        padding: 0 6%;
        min-height: 680px;

        h2 {
            font-size: 20px;
        }
    }

    .CRMLogInput {
        font-size: 14px !important;
    }

    .CRMLogBtn {
        font-size: 14px;
        height: 40px;
    }

    .CRMLogIn {
        h4 {
            font-size: 16px;
            text-align: center;
        }
    }
}

@media (max-width: 390px) {
    .CRMLogInput {
        width: calc(100% - 28px);
        outline: none;
        padding: 14px;
        font-size: 16px;
        font-weight: 500;
        font-family: 'Manrope', sans-serif;
        border: solid 1px #EAEAEA;
        border-radius: 6px;

        &::placeholder {
            color: #A6A6A6;
        }
    }

    .CRMLogBtn {
        width: 100%;
    }
}